import * as React from "react"
import { useEffect } from "react"

import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/title_cookie"
import Quote from "../components/quote"

const NotFoundPage = () => {
  //   console.log(data)
  //   let pageContext, pageData

  // let nextPageData
  // nextPageData =
  //   pathname === "/"
  //     ? { slug: "seven_seconds_match", title: "Seven Seconds Match" }
  //     : pageContext.next

  // console.log(pageContext)
  // if (pageContext.next.pageTemplate === "introduction")
  //   nextPageData = { slug: "experts", title: "experts" }

  // const mountedRef = useRef(true)
  import("../js/index")
    .then(m => {
      // if (!mountedRef.current) return null
      m.default()
    })
    .catch(err => {
      console.error("Error during loading module: " + err)
    })
  const notFoundQuote = {
    quoteText: "Being fresh is more important than having money.",
    author: "Kanye West",
  }

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll-mobile")
      const isBrowser = () => typeof window !== "undefined"
      if (!isBrowser()) return
      if (Array.isArray(window.allTimeouts))
        window.allTimeouts.forEach(id => clearTimeout(id))
    }
  }, [])

  return (
    <Layout siteTitle={"roundtable sports | 404"}>
      <Seo title={"roundtable sports | 404"} />
      <section className="content">
        <div className="left-content full-length"></div>
        <div id="scene1" className="section center-content">
          <Title text={"404"} tinyTilteMobile={0} />
        </div>
        <div id="scene2" className="section center-content">
          <Quote data={notFoundQuote} />
        </div>
      </section>
      <div className="content">
        <nav className="center-content pagination">
          <Link className="h3" to="/">
            Next — Introduction <br />
          </Link>
        </nav>
      </div>
    </Layout>
  )
}

export default NotFoundPage
